<i18n lang="yaml">

  ru:
    PWPopUpCancel: "Отменить"
    PWPopUpSubmit: "Продолжить"
  en:
    PWPopUpCancel: "Cancel"
    PWPopUpSubmit: "Submit"
</i18n>

<template>
  <Transition name="fade" @after-enter="$emit('after-enter', $event)">
    <section
      v-show="isOpen"
      :class="$style.PWPopUpWrapper"
    >
      <div
        :class="$style.PWPopUpShadow"
        @click="toggle(false)"
      />
      <div
        :class="{
          [$style.PWPopUpCtx]: true,
          [$style.PWPopUpShowOverflow]: props.showOverflow
        }"
      >
        <header :class="$style.PWPopUpHeader">
          <h2 :class="$style.PWPopUpHeaderTitle">
            {{ props.title }}
          </h2>
          <button
            v-if="props.closable"
            :class="$style.PWPopUpHeaderCloseButton"
            @click="toggle(false)"
          >
            <CloseRoundedIcon :class="$style.PWPopUpHeaderCloseButtonIcon" />
          </button>
        </header>
        <div :class="$style.PWPopUpBody">
          <slot />
        </div>
        <footer v-if="!props.hideFooter" :class="$style.PWPopUpFooter">
          <slot name="footer" />
          <template v-if="!$slots.footer">
            <PWButton
              variant="outline"
              @click="() => { $emit('handle-cancel'); toggle(false) }"
            >
              {{ t$('PWPopUpCancel') }}
            </PWButton>
            <PWButton
              @click="() => { $emit('handle-submit') }"
            >
              {{ props.submitButtonText ? props.submitButtonText : t$('PWPopUpSubmit') }}
            </PWButton>
          </template>
        </footer>
      </div>
    </section>
  </Transition>
</template>

<script setup lang="ts">
import PWButton from '~/components/_ui/Buttons/PWButton.vue'
import CloseRoundedIcon from 'assets/icons/CloseRounded.svg?component'

const emit = defineEmits(['open', 'close', 'handle-submit', 'handle-cancel', 'after-enter'])

const { t: t$ } = useI18n()

const props = defineProps({
  title: {
    type: String,
    default: undefined
  },
  hideFooter: {
    type: Boolean,
    default: false
  },
  showOverflow: {
    type: Boolean,
    default: false
  },
  closable: {
    type: Boolean,
    default: true
  },
  submitButtonText: {
    type: String,
    default: ''
  }
})

const isOpen = ref(false)

// methods
const toggle = (bool: boolean) => {
  isOpen.value = bool
}

// watch
watch(isOpen, (newValue) => {
  emit(newValue ? 'open' : 'close')
})

// expose
defineExpose({
  toggle
})
</script>

<style module lang="scss">
@use 'assets/scss/mixins';

.PWPopUpWrapper {
  position: fixed;
  z-index: 9999;
  inset: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior-y: contain;
  overflow-y: auto;
  display: flex;
  background-color: hsla(0, 0%, 13%, 0.6);
}

.PWPopUpShadow {
  position: fixed;
  z-index: 1;
  inset: 0;
  width: 100%;
  height: 100%;
}

.PWPopUpCtx {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: var(--shadow-sm);
  background-color: var(--white);
  margin: auto;
  display: flex;
  flex-direction: column;
  color: var(--primary);
}

.PWPopUpShowOverflow {
  overflow: visible;
}

.PWPopUpHeader,
.PWPopUpFooter {
  --px: 24px;
  --py: 16px;

  padding: var(--py) var(--px);
  display: flex;
  gap: var(--py);
  align-items: center;
}

.PWPopUpHeader {
  justify-content: space-between;
}

.PWPopUpFooter {
  justify-content: flex-end;
}

.PWPopUpBody {
  --px: 24px;
  --py: 8px;

  padding: var(--py) var(--px);
  color: currentColor;

  @include mixins.body1;
}

.PWPopUpHeaderTitle {
  all: unset;
  display: inline-block;
  color: currentColor;

  @include mixins.h2;
}

.PWPopUpHeaderCloseButton {
  all: unset;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--grey-600);
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.PWPopUpHeaderCloseButtonIcon {
  --s: 24px;

  width: var(--s);
  height: var(--s);
}
</style>
